import * as ProductStatus from "../constants/product-status";

export default {
  methods: {
    async checkProductStatusForBuying(product, list) {
      const productId = product.id;

      const productStatus = product.isFree
        ? await this.$store.dispatch("getProductStatusFree", productId)
        : await this.$store.dispatch("getProductStatusCatalog", productId);

      const possibleErrors = {
        [ProductStatus.purchased]: this.$t("productPage.errors.someoneAlreadyPurchased"),
        //[ProductStatus.reserved]: this.$t("productPage.errors.someoneAlreadyReserved"),
        [ProductStatus.notFound]: this.$t("productPage.errors.notFound", {
          name: list?.ownerName || "",
        }),
      };

      // Uncomment for quick testing.
      //return possibleErrors[ProductStatus.purchased] || "";

      const errorMessage = possibleErrors[productStatus] || "";

      if (!errorMessage) {
        return null;
      }

      return errorMessage;
    },
  },
}
